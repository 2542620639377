/* eslint-env es6 */

angular.module('genius').component('adwordsAdGroupList', {
  templateUrl: 'adwords_ad_group_list.html',

  bindings: {
    campaign: '<',
  },

  controller: ['AdwordsAdGroupClient', 'AdwordsAdClient', function(AdwordsAdGroupClient, AdwordsAdClient) {
    this.change_ad_status = (ad, status) => {
      AdwordsAdClient.update(ad.id, {status}).then((updated_ad) => {
        _.assign(ad, updated_ad);
      });
    };

    this.load_ad_groups = () => {
      this.loading = true;
      AdwordsAdGroupClient.all(this.campaign.id).then((ad_groups) => {
        this.ad_groups = ad_groups;
      }).finally(() => {
        Reflect.deleteProperty(this, 'loading');
      });
    };

    this.$onChanges = (changes) => {
      if (changes.campaign) this.load_ad_groups();
    };
  }],
});
