/* eslint-env es6 */

angular.module('genius').factory('AdwordsAdGroupClient', ['AppConfig', '$http', function(AppConfig, $http) {
  return {
    all(campaign_id) {
      return $http.get(`${AppConfig.api_root_url}/adwords_ad_groups?adwords_campaign_id=${campaign_id}`).
        then(response => response.data.response.ad_groups);
    },

    create(campaign_id, data) {
      return $http.post(`${AppConfig.api_root_url}/adwords_ad_groups`, {ad_group: data, adwords_campaign_id: campaign_id}).
        then(response => response.data.response.ad_group);
    },
  };
}]);
