/* eslint-env es6 */

angular.module('genius').component('adwordsAdForm', {
  templateUrl: 'adwords_ad_form.html',

  bindings: {
    onSave: '&',
    onClose: '&',
    ad_group: '<adGroup',
  },

  controller: ['AdwordsAdClient', function(AdwordsAdClient) {
    this.save = (data) => {
      this.saving = true;
      AdwordsAdClient.create(this.ad_group.id, data).then(function() {
        this.onSave();
        this.onClose();
      }).finally(() => {
        Reflect.deleteProperty(this, 'saving');
      });
    };
  }],
});
