/* eslint-env es6 */

angular.module('genius').factory('AdwordsCampaignClient', ['AppConfig', '$http', function(AppConfig, $http) {
  return {
    all() {
      return $http.get(`${AppConfig.api_root_url}/adwords_campaigns`).
        then(response => response.data.response.campaigns);
    },

    create(data) {
      return $http.post(`${AppConfig.api_root_url}/adwords_campaigns`, {campaign: data}).
        then(response => response.data.response.campaign);
    },
  };
}]);
