/* eslint-env es6 */

angular.module('genius').component('adwordsCampaignList', {
  templateUrl: 'adwords_campaign_list.html',

  bindings: {
    onSelect: '&',
    selected_campaign: '<selectedCampaign',
  },

  controller: ['AdwordsCampaignClient', function(AdwordsCampaignClient) {
    this.select = campaign => this.onSelect({campaign});

    this.load_campaigns = () => {
      this.loading = true;
      AdwordsCampaignClient.all().then((campaigns) => {
        this.grouped_campaigns = _.values(_.groupBy(campaigns, 'network.id'));
      }).finally(() => {
        Reflect.deleteProperty(this, 'loading');
      });
    };

    this.$onInit = this.load_campaigns;
  }],
});
