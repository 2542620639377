/* eslint-env es6 */

angular.module('genius').component('adwordsAdGroupForm', {
  templateUrl: 'adwords_ad_group_form.html',

  bindings: {
    onSave: '&',
    onClose: '&',
    campaign: '<',
  },

  controller: ['AdwordsAdGroupClient', function(AdwordsAdGroupClient) {
    this.save = (data) => {
      this.saving = true;
      AdwordsAdGroupClient.create(this.campaign.id, data).then(() => {
        this.onSave();
        this.onClose();
      }).finally(() => {
        Reflect.deleteProperty(this, 'saving');
      });
    };
  }],
});
