/* eslint-env es6 */

angular.module('genius').factory('AdwordsNetworkClient', ['$http', 'AppConfig', function($http, AppConfig) {
  return {
    all() {
      return $http.get(`${AppConfig.api_root_url}/adwords_networks`).
        then(response => response.data.response.networks);
    },
  };
}]);
