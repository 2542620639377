/* eslint-env es6 */

angular.module('genius').factory('AdwordsAdClient', ['AppConfig', '$http', function(AppConfig, $http) {
  return {
    all(ad_group_id) {
      return $http.get(`${AppConfig.api_root_url}/adwords_ads?adwords_ad_group_id=${ad_group_id}`).
        then(response => response.data.response.ads);
    },

    create(ad_group_id, data) {
      return $http.post(`${AppConfig.api_root_url}/adwords_ads`, {ad: data, adwords_ad_group_id: ad_group_id}).
        then(response => response.data.response.ad);
    },

    update(ad_id, data) {
      return $http.put(`${AppConfig.api_root_url}/adwords_ads/${ad_id}`, {ad: data}).
        then(response => response.data.response.ad);
    },
  };
}]);
