/* eslint-env es6 */

angular.module('genius').component('adwordsCampaignForm', {
  templateUrl: 'adwords_campaign_form.html',

  bindings: {
    onSave: '&',
    onClose: '&',
  },

  controller: ['AdwordsNetworkClient', 'AdwordsCampaignClient', function(AdwordsNetworkClient, AdwordsCampaignClient) {
    this.available_adwords_networks = [];

    this.save = (data) => {
      this.saving = true;
      return AdwordsCampaignClient.create(data).then(function() {
        this.onSave();
        this.onClose();
      }).finally(() => {
        Reflect.deleteProperty(this, 'saving');
      });
    };

    AdwordsNetworkClient.all().then((networks) => {
      this.available_adwords_networks = networks;
    });
  }],
});
